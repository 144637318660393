import { Component, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from '@app/shared/models/alert.model';
import { Subscription } from 'rxjs';
import { User } from '@app/shared/models/user.model';
import { OnDestroy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { StringMap } from '@app/shared/models/common.model';

declare const $:any;

export abstract class AbstractBaseComponent implements OnDestroy {
    public pageTitle = 'Abstract';
    public pageIcon = 'fal fa-chart-area';
    public hasErrors = false;
    public errorsList: string[] = [];
    public employee: User;
    public httpError: HttpErrorResponse;
    public errorSubscription: any;
    public applicationEventSubscription: any;
    public alertModalSubscription: any;
    public onError = new EventEmitter<any>();
    public ready = false;
    public alert: Alert;
    public loading = false;
    protected subscriptions: Subscription[] = [];
    protected initializedDataTables: string[] = [];
    private tableOptions: StringMap[] = [];
    public dataTableName = 'datatable';
    protected dataTableInitialized = false;
    protected massIds: number[] = [];
    public pages: number[] = [];

    constructor() {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            console.log('Destroying subscription');
        });
    }

    protected subscribe(subscription: Subscription) {
        if (!this.subscriptions.includes(subscription)) {
            this.subscriptions.push(subscription);
        }
    }

    protected unsubscribe() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
    protected updatePagerLinks(page: number) {
        $('.page-item').removeClass('active');
        $('.page-item:eq(' + (page - 1) + ')').addClass('active');
    }
    protected isAdmin() {
        return false;
    }

    protected initDataTable(tableName: string = '') {
        const tableToBeInit = (tableName === '') ? this.dataTableName : tableName;
        const tableOptions = this.getTableOptions();
        const this_ = this;
        setTimeout(function() {
            const table: any = $('#' + tableToBeInit);
            if (this_.initializedDataTables.indexOf(tableToBeInit) >= 0) {
                table.DataTable();
            } else {
                this_.initializedDataTables.push(tableToBeInit);
                table.DataTable(tableOptions);
            }
        }, 500);
    }

    protected addTableOption(name: string, value: any) {
        this.tableOptions.push({name, value});
    }

    protected clearTableOptions() {
        this.tableOptions = [];
    }
    protected getTableOptions() {
        if (this.tableOptions.length === 0 ) {
            return {
                    scrollY: '500px',
                    scrollCollapse: true
                    };
        } else {
            const tableOptions: StringMap = {};
            this.tableOptions.forEach(option => {
                tableOptions[option.name] = option.value;
            });
            return tableOptions;
        }
    }

    protected setPages(pages: number) {
        this.pages = [];
        for (let i = 1; i <= pages; i++) {
            this.pages.push(i);
        }
    }

    protected addDataTableButtons() {
        this.addTableOption('dom', '<\'row mb-3\'<\'col-sm-12 col-md-6 d-flex align-items-center justify-content-start\'f>' +
        '<\'col-sm-12 col-md-6 d-flex align-items-center justify-content-end\'B>>' +
        '<\'row\'<\'col-sm-12\'tr>>' +
        '<\'row\'<\'col-sm-12 col-md-5\'i><\'col-sm-12 col-md-7\'p>>');
        this.addTableOption('buttons', [
            {
                extend: 'colvis',
                text: 'Column Visibility',
                titleAttr: 'Col visibility',
                className: 'btn-outline-default'
            },
            {
                extend: 'csvHtml5',
                text: 'CSV',
                titleAttr: 'Generate CSV',
                className: 'btn-outline-default',
                exportOptions: {
                    columns: "thead th:not(.no-export)"
                }
            },
            {
                extend: 'copyHtml5',
                text: 'Copy',
                titleAttr: 'Copy to clipboard',
                className: 'btn-outline-default',
                exportOptions: {
                    columns: "thead th:not(.no-export)"
                }
            },
            {
                extend: 'print',
                text: '<i class="fal fa-print"></i>',
                titleAttr: 'Print Table',
                className: 'btn-outline-default',
                exportOptions: {
                    columns: "thead th:not(.no-export)"
                }
            }

        ]);
    }

    protected loadingStart() {
        this.loading = true;
    }

    protected loadingFinished() {
        this.loading = false;
    }

    protected removeRow(rowId: string, dataTableName: string) {
        const table: any = $('#' + dataTableName);
        $('#' + rowId).remove();
        // table.draw();
    }

    protected removeElementByClass(clazzNmae: string) {
        $('.' + clazzNmae).remove();
    }

    protected removeElementById(id: string) {
        $('#' + id).remove();
    }

    protected check(obj: any, idKey: string = 'id') {
        const id = obj[idKey];
        const idIndex = this.massIds.indexOf(id);
        if (idIndex > -1) {
            this.massIds.splice(idIndex, 1);
        } else {
            this.massIds.push(id);
        }
    }

    protected resetMassIds() {
        this.massIds = [];
    }

    getFormValidationErrors(form: any) {
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                  Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                  });
            }
        });
    }

    public runJqueryBindings() {
        $('.custom-scroll').each(function(){
            $(this).slimScroll({
                railVisible: false,
                height: $(this).attr("data-scrollHeight")+'px',
                alwaysVisible: false,
            });
        });
    }

    public createRecord() {

    }

    public save() {

    }

    public getValue(obj: any, val: any, defaultValue: any = null): any {
        return (obj == null || !obj.hasOwnProperty(val)) ? defaultValue : obj[val];
    }
}
