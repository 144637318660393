import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { Outlet } from '@app/shared/models/outlet.model';
declare const $: any;
@Component({
  selector: 'app-outlet-switcher',
  templateUrl: './outlet-switcher.component.html',
  styleUrls: ['./outlet-switcher.component.css']
})

export class OutletSwitcher implements OnInit {

    public outlets: Outlet[] = [];
    constructor(
        private applicationDataService: ApplicationDataService,
        private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.fetchData();    
    }

    async fetchData() {
        this.outlets = await this.applicationDataService.getOutlets();
    }

    switchOutlet(outlet: Outlet) {
        try {
            this.authenticationService.setActiveOutletId(outlet.id);
            $('.outlet-switcher-modal').hide();
            window.location.reload();
        } catch(error) {
            alert(error);
        }
    }
}
