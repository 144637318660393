import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ListDropDownOption } from '@app/shared/components/list-dropdown/list-dropdown.component';
import { Setup } from '@app/core/model/setup.model';
import { CompaniesService } from '@app/vendor/settings/companies.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent extends AbstractBaseComponent implements OnInit {
    public companyForm: FormGroup;
    public firstOutletForm: FormGroup;
    public inventorySettingsForm: FormGroup;
    public currenciesList: ListDropDownOption[] = [];
    public dateFormats: ListDropDownOption[] = [];
    public timeFormats: ListDropDownOption[] = [];
    public step: string = 'company-setup';
    private setup: Setup = {company_detail: {name: ''}, company_general_settings: null, first_outlet: null, inventory_settings: null};
    
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        private applicationDataService: ApplicationDataService,
        private companiesService: CompaniesService,
        protected profileService: ProfileService) {
        super();
    }
    
    ngOnInit() {
        this.prepareDropdownsData();
        this.initCompanyForm();
        this.initShopForm();
        this.initInventorySettingsForm();
    }
    
    public async signup() {
        try {

        } catch (error) {
            this.httpError = error;
            this.hasErrors = true;
        }
    }
    
    public initCompanyForm() {
        this.companyForm = this.formBuilder.group({
            company_name: ['', [Validators.required]],
            date_format: ['', [Validators.required]],
            time_format: ['', [Validators.required]],
            default_currency: ['', [Validators.required]],
            allow_over_selling: [false, [Validators.required]],
        });
    }

    public initShopForm() {
        this.firstOutletForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.email]],
            phone_number: ['', [Validators.required]],
            city: ['', [Validators.required]],
            address: ['', [Validators.required]],
            post_box: ['', [Validators.required]],
        });
    }

    public initInventorySettingsForm() {
        this.inventorySettingsForm = this.formBuilder.group({
            invoice_prefix: ['', [Validators.required]],
            invoice_prefix_separator: ['', [Validators.required]],
            item_prefix: ['', [Validators.required]],
            item_prefix_separator: ['', [Validators.required]],
        });
    }

    private prepareDropdownsData() {
        this.currenciesList = this.applicationDataService.getCurrencies();
        this.timeFormats = this.applicationDataService.getTimeFormats();
        this.dateFormats = this.applicationDataService.getDateFormats();
    }

    public saveCompanySetup() {
        this.setup.company_general_settings = this.companyForm.value;
        this.setup.company_detail.name = this.companyForm.controls['company_name'].value;
        this.goToStep('shop-setup');
    }

    public saveOutlet() {
        this.setup.first_outlet = this.firstOutletForm.value;
        this.goToStep('shop-settings-setup');
    }

    public saveInventorySettings() {
        this.setup.inventory_settings = this.inventorySettingsForm.value;
        this.saveFinalSettings();
    }

    public goToStep(stepName: string) {
        this.step = stepName;
    }

    public async saveFinalSettings() {
        try {
            const setup = await this.companiesService.saveSetup(this.setup);
            this.router.navigate(['portal/dashboard'], {
                replaceUrl: true,
                queryParams: {
                    setup: 'done'
                }
            });
        } catch(error) {
            console.log(error);
        }
    }
}
