import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ClientAuthenticationService } from '@app/client-site/client-authentication.service';


@Injectable()
export class ClientAuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: ClientAuthenticationService
  ) {}

  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/client'], {
      replaceUrl: true
    });
    return false;
  }
}
