<div *ngIf="!appDataLoaded" style="margin-top: 300px;">
    <app-loading>Putting things together...</app-loading>
</div>
<div class="page-wrapper" *ngIf="appDataLoaded">
    <div class="page-inner">
        <!-- BEGIN Left Aside -->
        <aside class="page-sidebar">
            <div class="page-logo">
                <a routerLink="/portal/dashboard"
                    class="page-logo-link press-scale-down d-flex align-items-center position-relative">
                    <img src="./assets/theme/img/logo.svg" alt="Options Legion" aria-roledescription="logo" />
                </a>
            </div>
            <app-sidebar></app-sidebar>
        </aside>
        <div class="page-content-wrapper">
            <app-header></app-header>
            <main id="js-page-content" role="main" class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
                <app-portal-footer></app-portal-footer>
            </main>
            <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
            <div class="modal fade" id="alert-modal-md" tabindex="-1" role="dialog" aria-hidden="true"
                style="z-index: 9999">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" [ngClass]="{'bg-success': alert?.type === alertTypes.Success,
                                                        'bg-info': alert?.type === alertTypes.Info,
                                                        'bg-danger': alert?.type === alertTypes.Error,
                                                        'bg-default': alert?.type === null
                                                       }">
                            <h4 class="modal-title">
                                {{alert?.title}}
                                <small class="m-0 text-muted">
                                    {{alert?.sub_title}}
                                </small>
                            </h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" style="color: #fff"><i class="fal fa-times"></i></span>
                            </button>
                        </div>
                        <div class="modal-body" [innerHTML]="alert?.body"></div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default shadow-0 rounded-0"
                                data-dismiss="modal">{{alert?.close_text}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
