import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UsersResponse, UserResponse, UsersFilter } from './models/user.model';
import { User } from '@app/shared/models/user.model';
import { LoginHistoryResponse } from '../login-history/models/login-history.model';
import { GenericFilter } from '@app/shared/models/generic_filter.model';
import { UserGroupsResponse } from '@app/shared/models/groups.model';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseAPIClass {

    private readonly URL_FETCH_USERS: string = 'users';
    private readonly URL_FETCH_LOGIN_HISTORY: string = 'users/login_history';
    private readonly URL_FETCH_PROFILE: string = 'me';
    private readonly URL_CREATE_USERS: string = 'users';
    private readonly URL_UPDATE_USERS: string = 'users';
    private readonly URL_DELETE_USERS: string = 'users/delete/{id}';
    private readonly URL_USER_GROUPS: string = 'users/{id}/groups';
    private readonly URL_CHANGE_PASSWORD: string = 'users/change_password/{id}';

    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }

    public async fetchUsersAsync(filters: UsersFilter) {
        return await this.getAsync<UsersResponse>(this.URL_FETCH_USERS, filters, {}, false).toPromise();
    }

    public async fetchLoginHistoryAsync(filter: GenericFilter) {
        return await this.getAsync<LoginHistoryResponse>(this.URL_FETCH_LOGIN_HISTORY, filter, {}, false).toPromise();
    }

    public async fetchMyProfileAsync() {
        return await this.getAsync<UserResponse>(this.URL_FETCH_PROFILE, {}, {}, false).toPromise();
    }

    public async fetchUserGroupsAsync(userId: number) {
        const url = this.URL_USER_GROUPS.replace('{id}', userId.toString());
        return await this.getAsync<UserGroupsResponse>(url, {}, {}, false).toPromise();
    }

    public async saveUserAsync(user: User) {
        return await this.postAsync<UserResponse>(this.URL_CREATE_USERS, user, {}, false).toPromise();
      }

    public async updateUserAsync(user: User) {
        return await this.putAsync<UserResponse>(this.URL_UPDATE_USERS, user, {}, false).toPromise();
    }

    public async deleteUserAsync(user: User) {
        const url = this.URL_DELETE_USERS.replace('{id}', user.id.toString());
        return await this.deleteAsync<UserResponse>(url, user, {}, false).toPromise();
    }

      public async changePasswordAsync(user: User) {
        const url = this.URL_CHANGE_PASSWORD.replace('{id}', user.id.toString());
        return await this.postAsync<UserResponse>(url , user, {}, false).toPromise();
    }

    public async userDeleteByIdAsync(user: User) {
        const url = this.URL_CHANGE_PASSWORD.replace('{id}', user.id.toString());
        return await this.postAsync<UserResponse>(url  , user, {}, false).toPromise();
    }
}
