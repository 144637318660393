import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { Credentials } from '@app/core/model/authentication.model';
import { SetupStatus } from '@app/core/model/setup.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ProfileService } from '@app/portal/settings/profile/profile.service';

declare const $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends AbstractBaseComponent implements OnInit {
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  public paymentNeeded = false;
  public accountSignupEmailSent = false;
  @ViewChild('loginBtn') loginBtn: ElementRef;
  @ViewChild('autofocus') email: ElementRef;

  isLoading = false;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private applicationDataService: ApplicationDataService,
    private authenticationService: AuthenticationService) {
      super();
  }

    ngOnInit() {
        this.createForm();
        const params = this.router.parseUrl(this.router.url).queryParams;
        if(!!params.signup && params.signup === 'done') {
            this.accountSignupEmailSent = true;
        }
    }

    ngAfterViewInit() {
      setTimeout(() => {
        this.email.nativeElement.focus();
      }, 500);
    }

    async login() {
        this.loadingStart();
        this.authenticationService
          .login(this.loginForm.value)
          .subscribe(
            async (credentials: Credentials) => {
                if(credentials.status == SetupStatus.SetupCompleted) {
                    const client = await this.profileService.getProfileAsync();
                    this.router.navigate(['/portal/dashboard'], {
                        replaceUrl: true
                    });
                } else if(credentials.status == SetupStatus.SetupIncomplete) {
                    this.router.navigate(['/setup'], {
                        replaceUrl: true
                    });
                }
                this.loadingFinished();
            }, (errorRequest) => {
                this.loadingFinished();
                this.errorsList = this.applicationDataService.getRequestErrors(errorRequest);
            }
        );
  }

  resetPassword() {
    // TODO: Implement Reset Password
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
