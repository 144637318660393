<div class="comments-wrapper p-2">
    <div *ngIf="!ready" class="loading">
        <div class="panel-tag">
            Loading...
        </div>
    </div>

    <div *ngIf="ready && comments.length == 0">
        <div class="card p-1">
            <blockquote class="blockquote mb-0 card-body">
                <p class="text-center">No comments</p>
            </blockquote>
        </div>
    </div>

    <div *ngFor="let comment of comments">
        <div class="chat-segment chat-segment-get">
            <div class="chat-message w-100 p-2">
                <p class="commenter text-muted">
                    {{comment.commenter.first_name}} {{comment.commenter.last_name}}
                </p>
                <span [innerHtml]="comment.comment"></span>
            </div>
            <div class="fw-300 text-muted mt-1 fs-xs text-right">
                {{comment.created_at | date: 'medium'}}
            </div>
        </div>
    </div>
</div>
<div class="add-new-comment-wrapper">
    <app-add-comment [module]="module" [relation_id]="relation_id"></app-add-comment>
</div>
