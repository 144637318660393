import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { NavigationMenu, NavItemType } from '@app/shared/models/portal-sidebar.model';
import { UserAccessGroupEnum } from '@app/shared/models/groups.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends AbstractBaseComponent implements OnInit {
    public NavItemType: NavItemType;
    public NavbarItems: NavbarItem[] = [];
    public menuReady = false;
    constructor(
        protected profileService: ProfileService,
        protected applicationDataService: ApplicationDataService,

    private router: Router) {
        super();
    }

    async ngOnInit() {
        this.employee = await this.profileService.getProfileAsync();
        this.loadSidebarFor();
        this.ready = true;
    }

    public gotoPage(navbarItem: NavbarItem) {
        if (navbarItem.link !== 'javascript:;') {
            if (navbarItem.link.includes('generic')) {
                this.redirectTo(navbarItem.link);
            } else {
                this.router.navigateByUrl(navbarItem.link);
            }
        }
    }

    async loadSidebarFor(navbarItem?: NavbarItem) {
        if(this.applicationDataService.isAppDataAvailable()) {
            this.NavbarItems = await this.applicationDataService.processNavigation(NavigationMenu);
            this.buildNavigationMenu();
        }
    }

    private buildNavigationMenu() {
        const navSpeed = 500;
        const 	navClosedSign =  'fal fa-angle-down';
        const   navOpenedSign = 'fal fa-angle-up';
        const navInitalized = 'js-nav-built';
        setTimeout(function() {
            (<any>$)('#js-nav-menu').navigation({
                accordion : true,
                speed : navSpeed,
                closedSign : '<em class="' + navClosedSign + '"></em>',
                openedSign : '<em class="' + navOpenedSign + '"></em>',
                initClass: navInitalized
            });
        }, 400);
    }

    public get navType(): typeof NavItemType {
        return NavItemType;
    }

    private redirectTo(uri: string){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate([uri]));
    }
}

export interface NavbarItem {
  id?: number;
  title?: string;
  link?: string;
  icon?: string;
  subMenu?: NavbarItem[];
  type?: NavItemType;
  accessLevel?: UserAccessGroupEnum[];
}
