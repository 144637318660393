import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutes } from '@app/app.routing';
import { AppComponent } from '@app/app.component';
import { AuthenticationGuard, CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { SidebarComponent } from './portal/sidebar/sidebar.component';
import { ProfileService } from './portal/settings/profile/profile.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ApplicationDataService } from './core/service/application-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SetupComponent } from './portal/setup/setup.component';
import { ClientAuthenticationGuard } from './client-site/client-authentication.guard';

@NgModule({
  declarations: [AppComponent, SetupComponent],
  exports: [SidebarComponent],
  imports: [
    CoreModule,
    AuthenticationModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    RouterModule.forRoot(AppRoutes),
    SharedModule,
//    FullCalendarModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
  providers: [
    ApplicationDataService,
    ProfileService,
    ClientAuthenticationGuard,
    AuthenticationGuard],
})
export class AppModule {}
