import { Routes } from '@angular/router';
import { AuthenticationGuard } from './core';
import { LoginComponent } from './authentication/login';
import { SetupComponent } from './portal/setup/setup.component';
import { FullComponent } from './shared';

export const AppRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full', canActivate: [AuthenticationGuard], },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LoginComponent
    },
    {
        path: 'client',
        loadChildren: () => import('./client-site/client-site.module').then(m => m.ClientSiteModule),
    },
    {
        path: 'setup',
        component: SetupComponent
    },
    {
        path: 'portal',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
        component: FullComponent,
    }
];
