import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { User } from '@app/shared/models/user.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { ToastrService } from '@app/shared/services/toastr.service';
import { UserResponse } from '../models/user.model';
import { UsersService } from '../users.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends AbstractBaseComponent implements OnInit {
  public changePasswordForm: FormGroup;
  @Output() emitData = new EventEmitter();
  @Input() public user: User;
  public Type:string = 'password';


    constructor (protected profileService: ProfileService,
        private applicationEvent: ApplicationEvent,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private usersService: UsersService) {
        super();
    }

    public setUser(user: User) {
        this.user = user;
    }

    public closeModal() {
        this.applicationEvent.fireGenericEvent({name: 'close-modal'});
    }

    public async save() {
        try {
            const user: User = this.changePasswordForm.value;
            user.id = this.user.id;
            user.password_confirmation = user.password;
            const response: UserResponse = await this.usersService.changePasswordAsync(user);
            this.toastrService.toastSuccess('Password has been Changed');
            this.userSaved();
        } catch (error) {
            if (error.constructor.name !== 'HttpErrorResponse') {
              this.toastrService.toastError(error);
            }
          }
    }
    public userSaved() {
      this.emitData.emit({ name: 'user-saved' });
  }

    public close() {
      this.emitData.emit({ name: 'close'});
    }

    public action() {
        if (this.Type === 'password') {
          this.Type = 'text';
        } else {
          this.Type = 'password';
        }
      }


    public generatePassword(length: number) {
        let result           = '';
       const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.changePasswordForm.controls['password'].setValue(result);
     }

  ngOnInit() {
      this.changePasswordForm = this.formBuilder.group({
        password : ['', [Validators.required]],
        password_confirmation: ['', []],
      });
      this.ready = true;
  }
}
