export const SettingsSubMenu = [
    {
        id: 0,
        title: 'Employment',
        link: 'javascript:;',
        icon: 'fal fa-shield-alt',
        subMenu: [
            {
                id: 14,
                title: 'Job Types',
                link: '/portal/generics/job_types',
                icon: 'fal fa-cogs',
            },
            {
                id: 29,
                title: 'Designations',
                link: '/portal/generics/designations',
                icon: 'fal fa-cogs',
            },
             {
                id: 29,
                title: 'City',
                link: '/portal/generics/cities',
                icon: 'fal fa-cogs',
            },
        ]
    },
    // {
    //     id: 0,
    //     title: 'Attendance',
    //     link: 'javascript',
    //     icon: 'fal fa-shield-alt',
    //     subMenu: [
    //         {
    //             id: 29,
    //             title: 'Work Schedules',
    //             link: '/portal/generics/work_schedules',
    //             icon: 'fal fa-cogs',
    //         },
    //         {
    //             id: 29,

    //             title: 'Projects',
    //             link: '/portal/ems/projects',
    //             icon: 'fal fa-cogs',
    //         }
    //     ]
    // },
     {
         id: 24,
         title: 'Tools',
         link: '/portal/ems/tools',
         icon: 'fal fa-link',
     },
    {
        id: 25,
        title: 'Assets',
        link: 'javascript:;',
        icon: 'fal fa-cogs',
        subMenu: [
            {
                id: 29,
                title: 'All Assets',
                link: '/portal/ems/assets',
                icon: 'fal fa-cogs',
            },
            {
                id: 29,
                title: 'Asset-Types',
                link: '/portal/generics/asset_types',
                icon: 'fal fa-cogs',
            }
        ]
    },
    // {
    //     id: 26,
    //     title: 'Usage Units',
    //     link: '/portal/generics/usage_units',
    //     icon: 'fal fa-car',
    // },
    // {
    //     id: 20,
    //     title: 'Teams',
    //     link: '/portal/generics/teams',
    //     icon: 'fal fa-cogs',
    // },
    // {
    //     id: 27,
    //     title: 'Locations',
    //     link: '/portal/generics/locations',
    //     icon: 'fal fa-cogs',
    // },
      {
        id: 2,
        title: 'Users',
        link: '/portal/settings/users',
        icon: 'fal fa-users'
      },
      {
        id: 9,
        title: 'Email Templates',
        link: '/portal/settings/email-templates',
        icon: 'fal fa-email'
      },
    //   {
    //     id: 2,
    //     title: 'Groups',
    //     link: '/portal/settings/groups/list',
    //     icon: 'fal fa-object-ungroup'
    //   },
      {
        id: 3,
        title: 'Login History',
        link: '/portal/settings/login-history',
        icon: 'fal fa-list'
      },

      {
        id: 5,
        title: 'Default SMTP',
        link: '/portal/settings/default-smtp',
        icon: 'fal fa-at'
      },
      {
        id: 6,
        title: 'Workflow',
        link: '/portal/settings/workflows/list',
        icon: 'fal fa-tasks'
      },
      {
        id: 8,
        title: 'SMTP Connections',
        link: '/portal/settings/smtp-connections',
        icon: 'fal fa-server'
      },
      {
        id: 27,
        title: 'Field Options',
        link: '/portal/settings/field-options',
        icon: 'fal fa-cogs',
      },
      {
        id: 28,
        title: 'Activity Log',
        link: '/portal/activity-log',
        icon: 'fal fa-history'
      }
];
