import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ApplicationEvent } from './shared/services/alert-modal.service';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
    genericEvent: any;
    constructor(private applicationEvent: ApplicationEvent,
                private dynamicScriptLoaderService: DynamicScriptLoaderService) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        const scriptsToLoad: string[] = [
            'flot-charts',
        ];
        this.dynamicScriptLoaderService.load(scriptsToLoad).then((scripts) => {
            this.applicationEvent.fireGenericEvent({whatHappened: 'scripts-loaded'});
        });
    }
}
