import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlankComponent } from '@app/shared/layouts/blank/blank.component';
import { SpinnerComponent } from '@app/shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonLoaderComponent } from '@app/shared/button-loader';
import { FormErrorWrapperComponent } from '@app/shared/form-error-wrapper/form-error-wrapper.component';
import { SidebarComponent } from '@app/portal/sidebar/sidebar.component';
import { HeaderComponent } from '@app/portal/header/header.component';
import { DashboardItemComponent } from '@app/dashboard/dashboard/dashboard-item/dashboard-item.component';
import { RequestErrorAlertComponent } from './request-error-alert/request-error-alert.component';
import { SubHeaderComponent } from '@app/portal/sub-header/sub-header.component';
import { NotFoundComponent } from '@app/authentication/404';
import { SignupComponent } from '@app/authentication/signup';
import { LoginComponent } from '@app/authentication/login';
import { SupportComponent } from '@app/portal/support/support.component';
import { ForgotPasswordComponent } from '@app/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/authentication/reset-password/reset-password.component';
import { CommonModule } from '@angular/common';
import { ModalLoaderComponent } from '@app/portal/modal-loader/modal-loader.component';
import { CommentsModule } from '@app/portal/comments/comments.module';
import { DataTableComponent } from '@app/portal/datatable/datatable.component';
import { DataTableRowComponent } from '@app/portal/datatable/datatable.row.component';
import { PeopleLookupComponent } from './components/people-lookup/people-lookup.component';
import { ConfirmDeleteDirective } from './directives/confirm-delete.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog-component/confirmation-dialog-component';

import { GenericFieldOptionsComponent } from './components/generic-field-options/generic-field-options.component';
import { TaskWidgetsComponent } from '@app/portal/tasks/task-widgets/task-widgets.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CalendarWidgetsComponent } from '@app/portal/calendar/calendar-widgets/calendar-widgets.component';
import { ChangePasswordComponent } from '@app/portal/settings/users/change-password/change-password.component';
import { BrandsDropDownComponent } from './components/brands-drop-down/brands-drop-down.component';
import { ModulesDropDownComponent } from './components/modules-drop-down/modules-drop-down.component';
import { UserDigestComponent } from './components/user-digest/user-digest.component';
import { FullComponent } from './layouts/full/full.component';
import { PortalFooterComponent } from '@app/portal/footer/footer.component';
import { AppSettingsModalComponent } from '@app/portal/app-settings-modal/app-settings-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { OtherActivitiesComponent } from '@app/dashboard/dashboard/widgets/other-activities/other-activities.component';
import { EmployeesDropDownComponent } from './components/employees-drop-down/employees-drop-down.component';
import { TextHolderComponent } from './components/text-holder/text-holder.component';
import { GenericLocationsDropDownComponent } from './components/generic-locations-drop-down/generic-locations-drop-down.component';
import { GenericDropDownComponent } from './components/generic-dropdown/generic-dropdown.component';
import { Select2Directive } from './directives/select2-directive';
import { ListDropdownComponent } from './components/list-dropdown/list-dropdown.component';
import { CountriesDropDownComponent } from './components/countries-drop-down/countries-drop-down.component';
import { OutletsDropDownComponent } from './components/outlets-drop-down/outlets-drop-down.component';
import { SuppliersDropDownComponent } from './components/suppliers-drop-down/suppliers-drop-down.component';
import { UnitPriceDropdownComponent } from './components/unit-price-dropdown/unit-price-dropdown.component';
import { VendorDropdownComponent } from './components/vendors-dropdown/vendor-dropdown.component';
import { OutletSwitcher } from './components/outlet-switcher/outlet-switcher.component';
import { NoRecordsComponent } from './components/no-records/no-records.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { DateRangePickerDirective } from './directives/date-range-picker.directive';
import { DatePickerDirective } from './directives/date-picker.directive';
import { InputMaskDirective } from './directives/input-mask.diretive';
import { DefaultCurrencyPipe } from './pipes/default-currency.pipe';
import { PrintPageComponent } from './components/print-page/print-page.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    CommentsModule
  ],
  declarations: [
    // CreateTimeoffComponent,
    OtherActivitiesComponent,
    SpinnerComponent,
    BlankComponent,
    FooterComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardItemComponent,
    RequestErrorAlertComponent,
    SubHeaderComponent,
    SupportComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    SignupComponent,
    LoginComponent,
    ModalLoaderComponent,
    DataTableComponent,
    DataTableRowComponent,
    ChangePasswordComponent,
    DataTableComponent,
    DataTableRowComponent,
    PeopleLookupComponent,
    ConfirmDeleteDirective,
    Select2Directive,
    ConfirmationDialogComponent,
    GenericFieldOptionsComponent,
    TaskWidgetsComponent,
    CalendarWidgetsComponent,
    LoadingComponent,
    NoRecordsComponent,
    BrandsDropDownComponent,
    ModulesDropDownComponent,
    UserDigestComponent,
    FullComponent,
    ModulesDropDownComponent,
    PortalFooterComponent,
    AppSettingsModalComponent,
    EmployeesDropDownComponent,
    TextHolderComponent,
    GenericLocationsDropDownComponent,
    GenericDropDownComponent,
    ListDropdownComponent,
    CountriesDropDownComponent,
    OutletsDropDownComponent,
    SuppliersDropDownComponent,
    UnitPriceDropdownComponent,
    VendorDropdownComponent,
    OutletSwitcher,
    ErrorListComponent,
    DateRangePickerDirective,
    DatePickerDirective,
    InputMaskDirective,
    DefaultCurrencyPipe,
    PrintPageComponent
  ],
  exports: [
    LoadingComponent,
    OtherActivitiesComponent,
    SpinnerComponent,
    BlankComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardItemComponent,
    RequestErrorAlertComponent,
    SubHeaderComponent,
    ModalLoaderComponent,
    DataTableComponent,
    DataTableRowComponent,
    ChangePasswordComponent,
    PeopleLookupComponent,
    ConfirmDeleteDirective,
    Select2Directive,
    ConfirmationDialogComponent,
    TaskWidgetsComponent,
    CalendarWidgetsComponent,
    SpinnerComponent,
    UserDigestComponent,
    BrandsDropDownComponent,
    GenericFieldOptionsComponent,
    ModulesDropDownComponent,
    PortalFooterComponent,
    AppSettingsModalComponent,
    FooterComponent,
    EmployeesDropDownComponent,
    TextHolderComponent,
    GenericLocationsDropDownComponent,
    GenericDropDownComponent,
    ListDropdownComponent,
    CountriesDropDownComponent,
    OutletsDropDownComponent,
    OutletSwitcher,
    NoRecordsComponent,
    ErrorListComponent,
    DateRangePickerDirective,
    DatePickerDirective,
    InputMaskDirective,
    DefaultCurrencyPipe
  ],
  entryComponents: [
    ModalLoaderComponent,
    ChangePasswordComponent,
    PeopleLookupComponent,
    ConfirmationDialogComponent,
    PrintPageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class SharedModule { }
