import { CompanySetting } from "@app/shared/models/company-settings.model";
import { Company } from "@app/shared/models/company.model";
import { Outlet } from "@app/shared/models/outlet.model";

export interface Setup {
    company_detail: Company;
    company_general_settings?: CompanySetting;
    first_outlet?: Outlet;
    inventory_settings?: any;
}

export enum SetupStatus {
    SetupCompleted = 'setup-completed',
    SetupIncomplete = 'incomplete-setup'
}