import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavbarItem } from '@app/portal/sidebar/sidebar.component';
import { UserGroup } from '@app/shared/models/groups.model';
import { BaseAPIClass } from '../class/baseAPI.class';
import { AppData, AppSecurityContext } from '../model/app-data.model';

@Injectable({ providedIn: 'root' })

export class ApplicationDataService extends BaseAPIClass {
    private readonly URL_FETCH_APP_DATA: string = 'auth/app_data';
    private appData: AppData = null;
    private userGroups: UserGroup[] = [];


    public isAppDataAvailable() {
        return this.appData != null;
    }
    public async fetchAppData() {
        return this.getAsync<AppData>(this.URL_FETCH_APP_DATA).toPromise();
    }

    public async getAppData() {
        this.appData =  (this.appData == null) ? await this.fetchAppData() : this.appData;
        if(Array.isArray(this.appData.app))
            this.appData = null;
        return this.appData;
    }

    public async refreshAppData() {
        this.appData = await this.fetchAppData();
    }

    public async getFieldOptions() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.field_options;
    }

    public async getBrands() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.brands;
    }

    public async getEmployees() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.employees;
    }

    public getUsers() {
        return this.appData.app.users;
    }

    public async getModules() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.modules;
    }

    public async getCountries() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.countries;
    }

    public async getVendorData() {
        if (this.appData == null ) {
            await this.getAppData();
        }
        return this.appData.app.vendor;
    }

    public async getLocations() {
      if (this.appData == null) {
        await this.getAppData();
      }
      return this.appData.app.locations;
    }

    public async getAssetTypes() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.locations;
      }

      public async getJobType() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.job_Types;
      }

    public async getVendorCategories() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.vendor.categories;
    }

    public async getVendorGroups() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.vendor.groups;
    }

    public async getCities() {
        if (this.appData == null) {
            await this.getAppData();
        }
        return this.appData.app.cities;
    }

    public async getExpenseCategories() {
        if (this.appData == null) {
            await this.getAppData();
        }
        return this.appData.app.expense_categories;
    }

    public async getSecurityContext() {
        if (this.appData == null) {
            await this.getAppData();
        }
        return this.appData.app.security_context;
    }

    public async getClients() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.clients;
    }

    public async getProducts() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.products;
    }

    public async getOutlets() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app?.vendor?.outlets;
    }

    public async getSuppliers() {
        if (this.appData == null) {
          await this.getAppData();
        }
        return this.appData.app.vendor.suppliers;
    }

    public async getCompanyData() {
        if (this.appData == null) {
            await this.getAppData();
        }
        return this.appData.app.company_data;
    }

    public async getStats() {
        if (this.appData == null) {
            await this.getAppData();
        }
        return this.appData.app.stats;
    }

    public userBelongsToGroup(groupId: number) {
        return this.userGroups.map(userGroup => userGroup.group_id).includes(groupId);
    }
    // TODO: In future this will be fecthed from Backend
    public getCurrencies() {
        return [
            {id: 0, name: 'USD'},
            {id: 1, name: 'GBP'},
            {id: 2, name: 'EUR'},
            {id: 3, name: 'PKR'},
            {id: 4, name: 'INR'},
        ];
    }

    // TODO: In future this will be fecthed from Backend
    public getTimeFormats() {
        return [
            {id: 0, name: '11:50 PM'},
            {id: 1, name: '23:50'},
        ];
    }


    // TODO: In future this will be fecthed from Backend
    public getDateFormats() {
        return [
            {id: 0, name: '31/12/2019'},
            {id: 1, name: '31/12/2019'},
            {id: 2, name: '31/Dec/2019'},
            {id: 3, name: '31-12-2019'},
            {id: 4, name: '12-31-2019'},
            {id: 5, name: '31-Dec-2019'}
        ];
    }

    // TODO: In future this will be fecthed from Backend
    public getCurrencySymbolPositions() {
        return [
            {id: 0, name: 'After'},
            {id: 1, name: 'Before'},
            {id: 2, name: 'Do no show'},
        ];
    }

    // TODO: In future this will be fecthed from Backend
    public getCurrencySeparators() {
        return [
            {id: 0, name: ","},
            {id: 1, name: "-"},
            {id: 2, name: "."},
            {id: 2, name: "space"},
        ];
    }

    public async processNavigation(navigation: NavbarItem[]) {
        const securityContext: AppSecurityContext = await this.getSecurityContext();
        this.userGroups = securityContext.groups;
        const userAllGroupIds = this.userGroups.map(userGroup => userGroup.group_id);
        const topLevelMenu: NavbarItem[] = [];
        for (const menu of navigation) {
            if ('accessLevel' in menu) {
                if (userAllGroupIds.some(accessLevelId => menu.accessLevel.includes(accessLevelId))) {
                    topLevelMenu.push(menu);
                }
            } else {
                topLevelMenu.push(menu);
            }
        }
        return topLevelMenu;
    }

    public getValidationErrorsAsList(request: HttpErrorResponse) {
        const responseErrors = request.error;
        const errorList: string[] = [];
        Object.keys(responseErrors).forEach((key: string)=> {
            if(Array.isArray(responseErrors[key])) {
                responseErrors[key].forEach((errorMessage: string) => {
                    errorList.push(errorMessage);
                });
            } else {
                if(key != 'code') {
                    errorList.push(responseErrors[key]);
                }
            }
        })
        return errorList;
    }

    public getServerError(request: HttpErrorResponse) {
        return [request.error.error];
    }

    public hasValidationErrors(request: HttpErrorResponse) {
        return (request.status === 422);
    }

    public hasServerError(request: HttpErrorResponse) {
        return (request.status === 500);
    }

    public hasAuthenticationError(request: HttpErrorResponse) {
        return (request.status === 401);
    }

    public getDefaultCurrency() {
        return this.appData.app.company_data.general_settings.default_currency;
    }

    public getRequestErrors(request: HttpErrorResponse) {
        if(this.hasServerError(request)) {
            return this.getServerError(request);
        } else if(this.hasValidationErrors(request)) {
            return this.getValidationErrorsAsList(request);
        } else if(this.hasAuthenticationError(request)) {
            return this.getServerError(request);
        }
        return [];
    }
}
