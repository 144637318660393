import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { Alert, AlertTypes } from '@app/shared/models/alert.model';
import { AuthenticationService, ErrorMessageService } from '@app/core';
import { AuthenticationEvent } from '@app/core/authentication/authentication.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';
declare const toastr: any;

@Component({
    selector: 'app-full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent extends AbstractBaseComponent implements OnInit {
  
  public appDataLoaded = false;
  constructor(
    private router: Router,
    protected profileService: ProfileService,
    private applicationEvent: ApplicationEvent,
    private errorMessageService: ErrorMessageService,
    private applicationDataService: ApplicationDataService,
    private authenticationService: AuthenticationService) {
        super();
        this.subscribe(this.applicationEvent.onAlert.subscribe(this.handleModalAlert.bind(this)));
        this.subscribe(this.authenticationService.onAuthenticationChange.subscribe(this.handleAuthenticationChange.bind(this)));
        this.subscribe(this.errorMessageService.errors$.subscribe(this.handleHttpRequestError.bind(this)));
    }

    ngOnInit() {
        this.loadAppData();
    }

    private async loadAppData() {
        const response = await this.applicationDataService.getAppData();
        const profile = await this.profileService.getProfileAsync(); // Preload
        this.appDataLoaded = true;
    }


  public handleModalAlert(alert: Alert) {
    this.alert = alert;
    (<any>$('#alert-modal-md')).modal();
  }

  public handleAuthenticationChange(event: AuthenticationEvent) {
    switch (event.status) {
        case 'logged-out':
            this.router.navigateByUrl('login');
        break;
    }
  }
    private getToastrOptions() {
        return {
            'closeButton': true,
            'debug': false,
            'newestOnTop': true,
            'progressBar': true,
            'positionClass': 'toast-top-right',
            'preventDuplicates': true,
            'showDuration': 300,
            'hideDuration': 100,
            'timeOut': 5000,
            'extendedTimeOut': 1000,
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut'
        };
    }

    private handleHttpRequestError(errors: ErrorModel.ErrorMessageObject[]) {
        let html = '';
        for (const errorObject of errors) {
            html += errorObject.error + '<br>';
        }

        if (html !== '') {
            toastr.error(html, this.getToastrOptions());
        }
    }

    public get alertTypes() {
        return AlertTypes;
    }
}
