<div class="container h-100">
  <div class="row align-items-center vh-100">
    <div class="col-12">
      <div class="row">
        <div class="d-none d-sm-block col-md-8 text-center">
          <div class="intro-image"></div>
        </div>
        <div class="col-12 col-md-4">
            <div
              class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
              <img src="./assets/theme/img/logo.svg" alt="Digibits ERP" aria-roledescription="logo">
                <span class="page-logo-text mr-1"></span>
            </div>
            <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
              <app-error-list [errorsList]="errorsList"></app-error-list>
              <div class="row" *ngIf="accountSignupEmailSent">
                <div class="col-12">
                    <div class='alert alert-success'>
                        <p class="p-0 m-0">Signup successful, please check your email to activate your account.</p>
                    </div>
                </div>
              </div>
              <form id="js-login" [formGroup]="loginForm" (ngSubmit)="login()" novalidate autocomplete="off">
                <div class="form-group">
                  <label class="form-label" for="username">Email</label>
                  <app-form-error-wrapper [control]="loginForm.controls['email']" [controlName]="'Email'">
                    <input class="form-control form-control-lg rounded-0" type="text" formControlName="email"
                      placeholder="Email" #autofocus/>
                  </app-form-error-wrapper>
                </div>
                <div class="form-group">
                  <label class="form-label" for="password">Password</label>
                  <app-form-error-wrapper [control]="loginForm.controls['password']" [controlName]="'Password'">
                    <input class="form-control form-control-lg rounded-0" type="password" formControlName="password"
                      placeholder="Password" />
                  </app-form-error-wrapper>
                </div>
                <div class="row no-gutters">
                  <div class="col-lg-12 pr-lg-1 my-2">
                    <button #loginBtn type="submit" [disabled]="loading"
                      class="shadow-0 btn btn-success btn-block btn-lg">SIGN IN</button>
                  </div>
                </div>
              </form>
              <div class="blankpage-footer text-center">
                <a [routerLink]="'/auth/forgot_password'"><strong>Forgot your password?</strong></a>
                <br>
                <span class="text-dark"> Create new account </span> <a [routerLink]="'/auth/signup'"><strong>Signup</strong></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
