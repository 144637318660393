    <div class="modal-header" style="background-color: white;">
        <h1>Print Invoice</h1>
        <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
    </div>
    <div class="modal-body " style="background-color: #f3f3f3;"  >
        <div class="container-fluid">
            <!-- <div class="row"> -->
                <!-- <div class="col-12"> -->
                     <div class="form-group row border" style="background-color: white;" >
                        <div class="col-12 invoice " id="invoice"  >

                        </div>
                    </div>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
    <div class="modal-footer" style="background-color: #f3f3f3;">
        <button class="btn btn-success shadow-0" (click)="doPrintPDF()">Print</button>
        <button class="btn btn-danger shadow-0" (click)="close()">Close</button>

    </div>

