import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { AuthenticationRoutes } from './authentication.routing';
import { RouterModule } from '@angular/router';
import { MarkAttendenceComponent } from './mark-attendence/mark-attendence.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    SharedModule,
    RouterModule.forChild(AuthenticationRoutes)
  ],
  declarations: [MarkAttendenceComponent]
})
export class AuthenticationModule {}
