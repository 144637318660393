import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/local-storage.service';
import { AuthenticationService } from '@app/core';

@Injectable({
    providedIn: 'root'
})

export class ClientAuthenticationService extends AuthenticationService {
    public readonly URL_LOGIN = 'auth/client-login';
    public readonly URL_LOGOUT = 'client/logout';
    protected AUTHENTICATION_CLIENT_EVENT_LOGGED_IN = 'client-logged-in';
    protected AUTHENTICATION_CLIENT_EVENT_LOGGED_OUT = 'client-logged-out';
    protected credentialsKey = 'client-credentials';

    constructor(
        protected httpClient: HttpClient,
        protected localStorageService: LocalStorageService) {
        super(httpClient, localStorageService);
        this.authType = 'Client';
        const savedCredentials = this.localStorageService.getItem(this.credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }
    public getAccessToken(): string | null {
        return this.credentials ? this.credentials.client.token : null;
    }
}


