import { GenericEvent } from './../../models/generic-event.model';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationEvent } from './../../services/alert-modal.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DynamicScriptLoaderService } from '@app/shared/services/dynamic-script-loader.service';
import { MediaService } from '@app/media/media.service';

@Component({
  selector: 'app-people-lookup',
  templateUrl: './people-lookup.component.html',
  styleUrls: ['./people-lookup.component.css']
})
export class PeopleLookupComponent extends AbstractBaseComponent
  implements OnInit {
  @Input() public type = 'any';
  @Input() public id = 'lookup';
  @Input() public showEmail = false;
  @Output() personSelected = new EventEmitter();
  // @Input()  public showPerson = new EventEmitter();

  constructor(
    private dynamicScriptLoaderService: DynamicScriptLoaderService,
    private mediaService: MediaService,
    private applicationEvent: ApplicationEvent
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(
      this.applicationEvent.onGenericEvent.subscribe(
        this.handleEvent.bind(this)
      )
    );
    this.initBinding(null);
  }

  private formatRepo(people: any) {
    if (people.loading) {
      return 'Loading...';
    }

    const name =
      people.first_name + ' ' + people.first_name + ' - ' + people.email;
    const markup =
      '<div class="select2-result-repository__title fs-lg fw-100">' +
      name +
      '</div>';
    return markup;
  }

  private processResults(data: any, params: any) {
    params.page = params.page || 1;
    return {
      results: data.results,
      pagination: {
        more: params.page * 30 < data.results.length
      }
    };
  }

  private escapeMarkup(markup: any) {
    return markup;
  }

  private initBinding(personName: string) {
    const this_ = this;
    this.dynamicScriptLoaderService.load(['select2']).then(scripts => {
      setTimeout(function() {
        const $select2: any = (<any>$)('#' + this_.id + '_lookup');
        $select2.select2({ dropdownParent: $('.modal') });
        $select2.select2({
          ajax: {
            url: '/api/people/lookup',
            dataType: 'json',
            delay: 0,
            headers: this_.mediaService.getHeaders(),
            data: function(params: any) {
              return {
                q: params.term,
                type: (<any>$)('#' + this_.id + '_lookup').data('type')
              };
            },
            processResults: this_.processResults,
            cache: true
          },
          placeholder: 'Search for people',
          escapeMarkup: this_.escapeMarkup,
          minimumInputLength: 2,
          templateResult: this_.formatRepo,
          templateSelection: function(selection: any) {
            if (selection.id !== '') {
                personName = null;
            }
            if (selection.id === '' && personName === null) {
              return selection.text;
            } else if (selection.id !== '' && personName === null) {
              this_.personSelected.emit({name: 'people-lookup-selected', payload: selection });
              return selection.first_name + ' ' + selection.last_name;
            }else if (personName !== null) {
              return personName;
            }
          }
        });
      }, 500);
    });
  }

  private handleEvent(event: GenericEvent) {
    switch (event.name) {
      case 'show-Person':
        this.initBinding(event.payload);
        break;
    }
  }
}
