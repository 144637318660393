import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from '../settings/profile/profile.service';
import { CommentsService } from './comments.service';
import { CommentsFilter, CommentsResponse, Comment } from '@app/shared/models/comment.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { GenericEvent } from '@app/shared/models/generic-event.model';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class ListCommentsComponent extends AbstractBaseComponent implements OnInit {

    @Input()  public module: string;
    @Input()  public module_id: number;
    @Input()  public relation_id: number;
    public comments: Comment[] = [];

    constructor(private commentsService: CommentsService,
        private applicationEvent: ApplicationEvent) {
        super();
        this.subscribe(this.applicationEvent.onGenericEvent.subscribe(this.handleEvent.bind(this)));
    }

    ngOnInit(): void {
        this.fetchCommentsAsync();
    }

    private async fetchCommentsAsync() {
        try {
            const commentsFilters: CommentsFilter = { module: this.module, relation_id: this.relation_id, module_id: this.module_id };
            const response: CommentsResponse = await this.commentsService.fetchCommentsAsync(commentsFilters);
            this.comments = response.comments.data;
            this.applicationEvent.fireGenericEvent('comments-loaded');
            this.ready = true;
        } catch (error) {
            console.log(error);
        }
    }

    private handleEvent(event: GenericEvent) {
        switch (event.name) {
          case 'comment-created':
              this.handleNewCommentAdded(event.payload);
          break;
        }
    }

    private handleNewCommentAdded(comment: Comment) {
        this.comments.unshift(comment);
        $('.comments-wrapper').scrollTop(0);
    }

}
