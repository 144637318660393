import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Client } from '@app/shared/models/client.model';
import { ClientFilter, ClientsResponse, ClientResponse, VerifyAccount, ResetPassword, ClientsDigest, ClientAddressesResponse, ClientProductPrice } from './models/clients.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseAPIClass {

    private readonly URL_FETCH_CLIENTS: string = 'clients';
    private readonly URL_FETCH_CLIENTS_DIGEST: string = 'clients_list';
    private readonly URL_UPDATE_CLIENT:string = 'client/update';
    private readonly URL_CREATE_CLIENT: string = 'create-client';
    private readonly URL_ACCOUNT_VERIFICATION: string = 'verify-account';
    private readonly URL_DELETE_CLIENT: string = 'clients/{id}';
    private readonly URL_CHANGE_PASSWORD: string = 'change-password';
    private readonly URL_RESEND_CODE: string = 'client/resend-token';
    private readonly URL_FETCH_CLIENT_ADDRESS: string = 'client/addresses';
    private readonly URL_FETCH_CLIENT_PRICES: string = 'client/{clientId}/product_prices';
    private readonly URL_SAVE_CLIENT_PRICES: string = 'client/product_prices';


    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }

    public async fetchClientSAsync(filter: ClientFilter) {
        return await this.getAsync<ClientsResponse>(this.URL_FETCH_CLIENTS, filter, {}, false).toPromise();
    }

    public async fetchClientAsync() {
        return await this.getAsync<ClientsResponse>(this.URL_FETCH_CLIENTS, {}, {}, false).toPromise();
    }

    public async fetchClientAddressesAsync(filter: ClientFilter) {
        return await this.getAsync<ClientAddressesResponse>(this.URL_FETCH_CLIENT_ADDRESS, filter, {}, false).toPromise();
    }

    public async fetchClientsDigestAsync(filter: ClientFilter) {
        return await this.getAsync<ClientsDigest>(this.URL_FETCH_CLIENTS_DIGEST, filter, {}, false).toPromise();
    }

    public async deleteClientAsync(client: Client) {
        const url = this.URL_DELETE_CLIENT.replace('{id}', client.id.toString());
        return await this.deleteAsync<ClientResponse>(url, {}, {}, false).toPromise();
    }

    public async createClientAsync(client: Client) {
        return await this.postAsync<ClientResponse>(this.URL_CREATE_CLIENT, client, {}, false).toPromise();
    }

    public async updateClientAsync(client:Client){
        return await this.postAsync<ClientResponse>(this.URL_UPDATE_CLIENT,client,{},false).toPromise();
    }

    public async verifyAccountAsync(verify: VerifyAccount) {
        return await this.postAsync<ClientResponse>(this.URL_ACCOUNT_VERIFICATION, verify, {}, false).toPromise();
    }

    public async resendCode(verify: VerifyAccount){
        return await this.postAsync<ClientResponse>(this.URL_RESEND_CODE,verify,{},false).toPromise();
    }

    public async changePassword(verify: ResetPassword){
        return await this.postAsync<ClientResponse>(this.URL_CHANGE_PASSWORD,verify,{},false).toPromise();
    }

    public async fetchClientPricesAsync(clientId: number) {
        return await this.getAsync<ClientProductPrice[]>(this.URL_FETCH_CLIENT_PRICES.replace('{clientId}', clientId.toString()), {}, {}, false).toPromise();
    }

    public async saveClientPricesAsync(payload: any){
        return await this.postAsync<ClientResponse>(this.URL_SAVE_CLIENT_PRICES, payload, {}, false).toPromise();
    }
}
