
<!-- <app-comments></app-comments> -->
<div class="row p-3">
    <div class="col-12">
        <div *ngIf="ready">
            <form action="#" [formGroup]="commentForm">
                <div class="form-group row">
                    <div class="col-sm-12">
                    <app-form-error-wrapper [control]="commentForm.controls['comment']" [controlName]="'Comment'">
                        <label class="form-label" for="comment">Comment:<span style="color: red;">*</span></label>
                        <textarea id="comment" class="cfm form-control" placeholder="Comment"
                        formControlName="comment"></textarea>
                    </app-form-error-wrapper>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-success shadow-0 ml-2" [disabled]="!this.commentForm.valid" (click)="save()">Save Comment</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

