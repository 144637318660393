<nav id="js-primary-nav" class="primary-nav" role="navigation">
  <ul id="js-nav-menu" class="nav-menu">
    <li *ngFor="let navbarItem of NavbarItems" [ngClass]="{'nav-title': navbarItem.type === navType.NavTitle}">
      <a href="javascript:;" (click)="gotoPage(navbarItem)" *ngIf="navbarItem.type === navType.Menu">
        <i class="{{ navbarItem.icon }}"></i>
        <span class="nav-link-text">{{ navbarItem.title }}</span>
      </a>
      <ul *ngIf="navbarItem.type === navType.Menu && navbarItem.subMenu">
        <li *ngFor="let subNavbarItem of navbarItem.subMenu">
            <a href="javascript:;" (click)="gotoPage(subNavbarItem)">
                <span class="nav-link-text">{{ subNavbarItem.title }}</span>
            </a>
            <ul *ngIf="subNavbarItem.subMenu">
                <li *ngFor="let subNavbarItem1 of subNavbarItem.subMenu">
                    <a href="javascript:;" (click)="gotoPage(subNavbarItem1)">
                        <span class="nav-link-text">{{ subNavbarItem1.title }}</span>
                    </a>
                </li>
            </ul>
        </li>
      </ul>
      <span *ngIf="navbarItem.type === navType.NavTitle">{{ navbarItem.title }}</span>
    </li>
  </ul>
  <div class="filter-message js-filter-message bg-success-600"></div>
</nav>
