import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core';
import { CompanySetting, CompanySettingResponse } from '@app/shared/models/company-settings.model';
import { Company, CompanyResponse } from '@app/shared/models/company.model';
import { Setup } from '@app/core/model/setup.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseAPIClass {
    private readonly URL_UPDATE_COMPANY: string = 'update_company';
    private readonly URL_UPDATE_COMPANY_SETTINGS: string = 'update_company_settings';
    private readonly URL_SAVE_COMPANY_SETUP: string = 'save_company_setup';

    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }

    public async companyUpdateAsync(company: Company) {
        return await this.postAsync<CompanyResponse>(this.URL_UPDATE_COMPANY, company, {}, false).toPromise();
    }

    public async updateCompanySettingsAsync(companySetting: CompanySetting) {
        return await this.postAsync<CompanySettingResponse>(this.URL_UPDATE_COMPANY_SETTINGS, companySetting, {}, false).toPromise();
    }

    public async saveSetup(setup: Setup) {
        return await this.postAsync<any>(this.URL_SAVE_COMPANY_SETUP, setup, {}, false).toPromise();
    }
}
