import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ProfileService } from '../settings/profile/profile.service';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AbstractBaseComponent implements OnInit {
    public user: User;
    constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService) {
        super();
    }

    async ngOnInit() {
        this.user = await this.profileService.getProfileAsync();
    }

    public async logoutAsync() {
        await this.authenticationService.logout().toPromise();
    }
}
