import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
declare var JSPM: any;
declare const $: any;
@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.css']
})
export class PrintPageComponent implements OnInit {
  title = 'JSPrintManager in Angular';
  printers: string[];
  selectedPrinter: any;
  isDefaultPrinterSelected = false;
  @Input() invoiceHTML: any;
  public emitData = new EventEmitter();
  constructor() {}

  ngOnInit() {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
      if (this.jspmWSStatus()) {
        JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
          this.printers = myPrinters;
        });
      }
    };
  }

  public invoiceHtml(invHtml: any) {
    $('.invoice').html(invHtml.invoice);
  }

  jspmWSStatus() {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      alert(
        'JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm'
      );
      return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
      alert('JSPM has blocked this website!');
      return false;
    }
  }

  doPrintZPL() {
    if (this.selectedPrinter !== 'undefined' && this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      // Set Printer type (Refer to the help, there many of them!)
      //https://www.neodynamic.com/Products/Help/JSPrintManager2.0/articles/jsprintmanager.html#client-printer-types
      if (this.isDefaultPrinterSelected) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      }

      // Set content to print...
      //Create Zebra ZPL commands for sample label
      var cmds = '^XA';
      cmds += '^FO20,30^GB750,1100,4^FS';
      cmds += '^FO20,30^GB750,200,4^FS';
      cmds += '^FO20,30^GB750,400,4^FS';
      cmds += '^FO20,30^GB750,700,4^FS';
      cmds += '^FO20,226^GB325,204,4^FS';
      cmds += '^FO30,40^ADN,36,20^FDShip to:^FS';
      cmds += '^FO30,260^ADN,18,10^FDPart number #^FS';
      cmds += '^FO360,260^ADN,18,10^FDDescription:^FS';
      cmds += '^FO30,750^ADN,36,20^FDFrom:^FS';
      cmds += '^FO150,125^ADN,36,20^FDAcme Printing^FS';
      cmds += '^FO60,330^ADN,36,20^FD14042^FS';
      cmds += '^FO400,330^ADN,36,20^FDScrew^FS';
      cmds += '^FO70,480^BY4^B3N,,200^FD12345678^FS';
      cmds += '^FO150,800^ADN,36,20^FDMacks Fabricating^FS';
      cmds += '^XZ';
      cpj.printerCommands = cmds;
      // Send print job to printer!
      cpj.sendToClient();
    }
  }

  changeCheck() {
    if (this.isDefaultPrinterSelected === false) {
      this.isDefaultPrinterSelected = true;
    } else if (this.isDefaultPrinterSelected === true) {
      this.isDefaultPrinterSelected = false;
    }
  }

  DPR() { // Get device dpi
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio;
    }
    return 1;
  }

  // Do PDF printing...
  doPrintPDF() {
    const this_ = this;
    const dom = document.querySelector('.invoice');
          const box = window.getComputedStyle(dom);
          // DOM node width and height after calculation
          const width = parseInt(box.width, 10);
          const height = parseInt(box.height, 10);
          // Get pixel ratio
          const scaleBy = this.DPR();
          // Create a custom canvas element
          const canvas = document.createElement('canvas');
          // Set the width and height of the canvas element attribute to the width and height of the DOM node * pixel ratio
          canvas.width = width * scaleBy;
          canvas.height = height * scaleBy;
          // Set canvas css width and height to DOM node width and height
          canvas.style.width = `${width}px`;
          canvas.style.height = `${height}px`;
          // Get the brush
          const context = canvas.getContext('2d');

    html2canvas(document.getElementById('invoice'),{canvas,scrollY:0,scrollX:0,useCORS: true }).then(function(
      canvas
    ) {
      if (this_.selectedPrinter !== 'undefined' && this_.jspmWSStatus()) {
        // Create a ClientPrintJob
        const cpj = new JSPM.ClientPrintJob();
        cpj.clientPrinter = new JSPM.DefaultPrinter();
        var b64Prefix = 'data:image/png;base64,';
        var imgBase64DataUri = canvas.toDataURL('image/png');
        var imgBase64Content = imgBase64DataUri.substring(
          b64Prefix.length,
          imgBase64DataUri.length
        );

        var myImageFile = new JSPM.PrintFile(
          imgBase64Content,
          JSPM.FileSourceType.Base64,
          'myFileToPrint.png',
          1
        );
        $('#pdfinv').html(
            '<img src="data:image/png;base64,' + myImageFile.fileContent+ '" />'
          );
        // $('#invPrint').
        cpj.files.push(myImageFile);
        //Send print job to printer!
        cpj.sendToClient();
      }
    });
  }

  print() {
    if (this.jspmWSStatus()) {
      //generate an image of HTML content through html2canvas utility
      html2canvas(document.getElementById('invoice'), { scale: 5 }).then(
        function(canvas) {
          //Create a ClientPrintJob
          var cpj = new JSPM.ClientPrintJob();
          //Set Printer type (Refer to the help, there many of them!)
          if ($('#useDefaultPrinter').prop('checked')) {
            cpj.clientPrinter = new JSPM.DefaultPrinter();
          } else {
            cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
          }
          //Set content to print...
          var b64Prefix = 'data:image/png;base64,';
          var imgBase64DataUri = canvas.toDataURL('image/png');
          var imgBase64Content = imgBase64DataUri.substring(
            b64Prefix.length,
            imgBase64DataUri.length
          );

          var myImageFile = new JSPM.PrintFile(
            imgBase64Content,
            JSPM.FileSourceType.Base64,
            'myFileToPrint.png',
            1
          );

          $('#pdfinv').html(
            '<img src="data:image/png;base64,' + myImageFile + '" />'
          );
          //add file to print job
          cpj.files.push(myImageFile);

          //Send print job to printer!
          cpj.sendToClient();
        }
      );
    }
  }
  close() {
    this.emitData.emit({ name: 'close' });
  }
}
