<header class="page-header" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
        <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative"
            data-toggle="modal" data-target="#modal-shortcut">
            <img alt="Digibits Technologies" aria-roledescription="logo" src="./assets/theme/img/logo.svg" />
            <span class="page-logo-text mr-1">Digibits POS</span>
            <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
        </a>
    </div>
    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-md-down dropdown-icon-menu position-relative">
        <a href="javascript:;" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
            title="Hide Navigation">
            <i class="ni ni-menu"></i>
        </a>
        <ul>
            <li>
                <a href="javascript:;" class="btn js-waves-off" data-action="toggle" data-class="nav-function-minify"
                    title="Minify Navigation">
                    <i class="ni ni-minify-nav"></i>
                </a>
            </li>
            <li>
                <a href="javascript:;" class="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed"
                    title="Lock Nheader-iconavigation">
                    <i class="ni ni-lock-nav"></i>
                </a>
            </li>
        </ul>
    </div>
    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
        <a href="javascript:;" class="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
            <i class="ni ni-menu"></i>
        </a>
    </div>

    <div class="ml-auto d-flex">
        <!-- app settings -->
        <div class="hidden-md-down">
            <a href="#" class="header-icon" data-toggle="modal" data-target=".js-modal-settings"  title="Layout Settings">
                <i class="fal fa-cog"></i>
            </a>
        </div>
        <div class="hidden-md-down">
            <a href="#" class="header-icon" data-toggle="modal" data-target=".outlet-switcher-modal" title="Switch Outlet">
                <i class="fal fa-browser"></i>
            </a>
        </div>
        <!-- app shortcuts -->
        <div>
            <div>
                <a href="#" class="header-icon" data-toggle="dropdown" title="My Apps">
                    <i class="fal fa-cube"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-animated w-auto h-auto">
                    <div
                      class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top"
                    >
                      <h4 class="m-0 text-center color-white">
                        Quick Shortcut
                        <small class="mb-0 opacity-80">User Applications & Addons</small>
                      </h4>
                    </div>
                    <div class="custom-scroll h-100">
                      <ul class="app-list">
                        <li>
                          <a
                            [routerLink]="['/portal/ems/employees']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-7 icon-stack-3x color-info-500"></i>
                              <i class="base-7 icon-stack-2x color-info-700"></i>
                              <i class="fal fa-users  icon-stack-1x text-white fs-lg"></i>
                            </span>
                            <span class="app-list-name">
                              Employees
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/ems/clients/list']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-2 icon-stack-3x color-primary-400"></i>
                              <i class="base-10 text-white icon-stack-1x"></i>
                              <i class="ni md-profile color-primary-800 icon-stack-2x"></i>
                            </span>
                            <span class="app-list-name">
                              Clients
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/vendor/invoices/list-invoices']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-9 icon-stack-3x color-success-400"></i>
                              <i class="base-2 icon-stack-2x color-success-500"></i>
                              <i class="ni ni-list icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                              Invoices
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/vendor/dashboard']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-7 icon-stack-3x color-info-500"></i>
                              <i class="base-7 icon-stack-2x color-info-700"></i>
                              <i class="ni ni-graph icon-stack-1x text-white"></i>
                            </span>
                            <span class="app-list-name">
                              Inventory
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/ems/estimates']"
                            class="app-list-item hover-white"
                          >
                          <span class="icon-stack">
                            <i class="base-7 icon-stack-3x color-info-500"></i>
                            <i class="base-7 icon-stack-2x color-info-700"></i>
                            <i
                              class="fal fa-dot-circle icon-stack-1x text-white fs-lg"
                            ></i>
                          </span>
                          <span class="app-list-name">
                            Estimates
                          </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/ems/expenses']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-5 icon-stack-3x color-primary-400"></i>
                              <i class="base-9 icon-stack-2x color-primary-300"></i>
                              <i class="base-7 icon-stack-1x fs-xxl color-primary-200"></i>
                              <i class="base-7 icon-stack-1x color-primary-500"></i>
                              <i
                                class="fa fa-university icon-stack-1x text-white opacity-85"
                              ></i>
                            </span>
                            <span class="app-list-name">
                              Expenses
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            [routerLink]="['/portal/vendor/fleet']"
                            class="app-list-item hover-white"
                          >
                            <span class="icon-stack">
                              <i class="base-5 icon-stack-3x color-primary-400"></i>
                              <i class="base-9 icon-stack-2x color-primary-300"></i>
                              <i class="base-7 icon-stack-1x fs-xxl color-primary-200"></i>
                              <i class="base-7 icon-stack-1x color-primary-500"></i>
                              <i  class="fal fa-box-open icon-stack-1x text-white opacity-85"
                              ></i>
                            </span>
                            <span class="app-list-name">
                              Fleet
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        <!-- activate app search icon (mobile) -->
        <div>
            <a href="#" data-toggle="dropdown"
                class="header-icon d-flex align-items-center justify-content-center ml-2">
                <img src="assets\images\user1.png"
                    class="profile-image rounded-circle" />
                <!-- you can also add username next to the avatar with the codes below: -->
                <span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">{{this.user?.first_name}}</span>
                <i class="ni ni-chevron-down hidden-xs-down"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div class="dropdown-header d-flex flex-row py-4 rounded-top">
                    <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                        <span class="mr-2">
                            <img src="assets\images\user1.png"
                                class="rounded-circle profile-image" />
                        </span>
                        <div class="info-card-text">
                            <div class="fs-lg text-truncate text-truncate-lg color-fusion-300">
                                {{this.user?.company.name}}
                            </div>
                            <span class="text-truncate text-truncate-md color-fusion-50">
                                {{this.user?.first_name}} {{this.user?.last_name}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider m-0"></div>
                <a routerLink="/portal/settings/profile" class="dropdown-item" >
                    <span data-i18n="drpdwn.settings">Profile</span>
                </a>
                <a routerLink="/portal/settings/profile" class="dropdown-item">
                    <span data-i18n="drpdwn.settings">Change Password</span>
                </a>
                <div class="dropdown-divider m-0"></div>
                <a href="#" class="dropdown-item" data-action="app-fullscreen">
                    <span data-i18n="drpdwn.fullscreen">Fullscreen</span>
                    <i class="float-right text-muted fw-n">F11</i>
                </a>
                <a href="#" class="dropdown-item" data-action="app-print">
                    <span data-i18n="drpdwn.print">Print</span>
                    <i class="float-right text-muted fw-n">Ctrl + P</i>
                </a>
                <div class="dropdown-multilevel dropdown-multilevel-left">
                    <div class="dropdown-item">
                        Language
                    </div>
                    <div class="dropdown-menu">
                        <a href="#?lang=en" class="dropdown-item active" data-action="lang" data-lang="en">English
                            (US)</a>
                    </div>
                </div>
                <div class="dropdown-divider m-0"></div>
                <a class="dropdown-item fw-500 pt-3 pb-3" href="javascript:;" (click)="logoutAsync()">Logout
                    <span class="float-right fw-n">...</span>
                </a>
            </div>
        </div>
    </div>
</header>
