<div>
    <div class="flex-1">
        <div class="container py-1 py-lg-1">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="card bg-white">
                        <div class="row">
                            <div class="col-12">
                                <div class="logo">
                                    <img src="./assets/theme/img/logo.svg" alt="Digibits Point Of Sale"
                                        aria-roledescription="logo">
                                </div>
                            </div>
                        </div>
                        <div class="form-1" *ngIf="step === 'company-setup'">
                            <h1 class="m-0 text-center fw-900">
                                Welcome! Let’s set up your account…
                            </h1>
                            <p class="text-center text-muted">Fill out the details below to set up your company account and
                                get started</p>
                            <form action="#" [formGroup]="companyForm">
                                <div id="companyform">
                                    <div class="form-group">
                                        <label class="form-label" for="last_name">Company Name<span
                                            style="color: red;">*</span></label>
                                        <app-form-error-wrapper [control]="companyForm.controls['company_name']"
                                          [controlName]="'Company Name'">
                                          <input class="form-control form-control-md" type="text" formControlName="company_name"
                                            placeholder="Company Name" />
                                        </app-form-error-wrapper>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="companyForm.controls['date_format']"
                                                [controlName]="'VAT No'">
                                                <label class="form-label" for="date_format"> Date Format<span
                                                    style="color: red;">*</span></label>
                                                <app-list-dropdown class="app-list-dropdown" [group]="companyForm" [valueField]="'name'" [control]="'date_format'" [name]="'date_format'" [listDropdown]="dateFormats"></app-list-dropdown>

                                            </app-form-error-wrapper>
                                        </div>
                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="companyForm.controls['time_format']"
                                                [controlName]="'VAT No'">
                                                <label class="form-label" for="time_format"> Time Format<span
                                                    style="color: red;">*</span></label>
                                                <app-list-dropdown class="app-list-dropdown" [group]="companyForm" [valueField]="'name'" [control]="'time_format'" [name]="'time_format'" [listDropdown]="timeFormats"></app-list-dropdown>
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="companyForm.controls['default_currency']"
                                                [controlName]="'VAT/Tax No'">
                                                <label class="form-label" for="default_currency"> Default Currency<span
                                                    style="color: red;">*</span></label>
                                                <app-list-dropdown class="app-list-dropdown" [group]="companyForm" [valueField]="'name'" [control]="'default_currency'" [name]="'default_currency'" [listDropdown]="currenciesList"></app-list-dropdown>
                                            </app-form-error-wrapper>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="custom-control custom-switch mt-5">
                                                <input type="checkbox" class="custom-control-input" id="allow_over_selling" formControlName="allow_over_selling">
                                                <label class="custom-control-label" for="allow_over_selling">Allow Overselling</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 my-4">
                                          <button #loginBtn type="submit" [disabled]="!companyForm.valid"
                                            class="shadow-0 btn btn-success btn-block btn-lg" (click)="saveCompanySetup()">NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="form-2" *ngIf="step === 'shop-setup'">
                            <h1 class="m-0 text-center fw-900">
                                Great! Let's create an outlet for you now..
                            </h1>
                            <p class="text-center text-muted">Fill out the details below to set up your outlet to get started</p>
                            <form action="#" [formGroup]="firstOutletForm" autocomplete="off">
                                <div id="firstOutletForm">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['name']"
                                                [controlName]="'Outlet/Shop Name'">
                                                <label class="form-label" for="name">Name<span
                                                        style="color: red;">*</span></label>
                                                <input type="text" id="name" class="cfm form-control rounded-0" placeholder="Name"
                                                    formControlName="name">
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['email']"
                                                [controlName]="'Email'">
                                                <label class="form-label" for="email">Email<span
                                                    style="color: red;">*</span></label>
                                                <input type="text" id="email" class="cfm form-control rounded-0" placeholder="Email"
                                                    formControlName="email">
                                            </app-form-error-wrapper>
                                        </div>

                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['phone_number']"
                                                [controlName]="'Phone No.'">
                                                <label class="form-label" for="phone_number">Phone<span
                                                    style="color: red;">*</span></label>
                                                <input type="text" id="phone_number" class="cfm form-control rounded-0" placeholder="Phone"
                                                    formControlName="phone_number">
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>



                                    <div class="form-group row ">
                                        <div class="col-sm-12">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['address']"
                                                [controlName]="'Outlet Address'">
                                                <label class="form-label" for="address">Address<span
                                                    style="color: red;">*</span></label>
                                                <textarea type="text" id="address" class="cfm form-control"
                                                    rows="2" placeholder="Where is this outlet located?"
                                                    formControlName="address"></textarea>
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['post_box']"
                                                [controlName]="'Post Box Or Zip'">
                                                <label class="form-label" for="post_box">Post Box/Zip<span
                                                    style="color: red;">*</span></label>
                                                <input type="text" id="post_box" class="cfm form-control rounded-0" placeholder="Post Box"
                                                    formControlName="post_box">
                                            </app-form-error-wrapper>
                                        </div>

                                        <div class="col-sm-6">
                                            <app-form-error-wrapper [control]="firstOutletForm.controls['city']"
                                                [controlName]="'City'">
                                                <label class="form-label" for="city">City<span
                                                    style="color: red;">*</span></label>
                                                <input type="text" id="city" class="cfm form-control rounded-0" placeholder="City"
                                                    formControlName="city">
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 my-4">
                                          <button #loginBtn type="submit" [disabled]="!firstOutletForm.valid"
                                            class="shadow-0 btn btn-success btn-block btn-lg" (click)="saveOutlet()">NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="form-2" *ngIf="step === 'shop-settings-setup'">
                            <h1 class="m-0 text-center fw-900">
                                Awesome! Now let's setup Inventory
                            </h1>
                            <p class="text-center text-muted">Fill out the details below to set up your inventor</p>
                            <form action="#" [formGroup]="inventorySettingsForm" autocomplete="off">
                                <div id="inventorySettingsForm">

                                    <div class="form-group row">
                                        <div class="col-sm-8">
                                            <app-form-error-wrapper [control]="inventorySettingsForm.controls['invoice_prefix']"
                                                [controlName]="'Product Code Prefix'">
                                                <label class="form-label" for="invoice_prefix">Invoice Prefix</label>
                                                <input type="text" id="invoice_prefix" class="cfm form-control rounded-0" placeholder="Invoice Prefix"
                                                    formControlName="invoice_prefix">
                                            </app-form-error-wrapper>
                                        </div>

                                        <div class="col-sm-4">
                                            <app-form-error-wrapper [control]="inventorySettingsForm.controls['invoice_prefix_separator']"
                                                [controlName]="'Invoice Code Prefix Separator'">
                                                <label class="form-label" for="invoice_prefix_separator">Invoice Separator</label>
                                                <input type="text" id="invoice_prefix_separator" maxlength="1" class="cfm form-control rounded-0" placeholder="Separator"
                                                    formControlName="invoice_prefix_separator">
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-8">
                                            <app-form-error-wrapper [control]="inventorySettingsForm.controls['item_prefix']"
                                                [controlName]="'Product Code Prefix'">
                                                <label class="form-label" for="item_prefix">Product Prefix</label>
                                                <input type="text" id="item_prefix" class="cfm form-control rounded-0" placeholder="Invoice Prefix"
                                                    formControlName="item_prefix">
                                            </app-form-error-wrapper>
                                        </div>

                                        <div class="col-sm-4">
                                            <app-form-error-wrapper [control]="inventorySettingsForm.controls['item_prefix_separator']"
                                                [controlName]="'Product Code Prefix Separator'">
                                                <label class="form-label" for="item_prefix_separator">Product Separator</label>
                                                <input type="text" id="item_prefix_separator" maxlength="1" class="cfm form-control rounded-0" placeholder="Separator"
                                                    formControlName="item_prefix_separator">
                                            </app-form-error-wrapper>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 my-4">
                                          <button #loginBtn type="submit" [disabled]="!companyForm.valid"
                                            class="shadow-0 btn btn-success btn-block btn-lg" (click)="saveInventorySettings()">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pos-relative">
                    <div class="pos-absolute right-wrapper">
                        <h1 class="m-0 fw-900">What do you get with Stack Terminal:</h1>
                        <p>
                            A competitive advantage by successfully integrating an all in one HR software.
                        </p>
                        <p>
                            One platform your will ever need to achieve all of your CRM needs.
                        </p>
                        <p>Save managers up to 700 hours of work each year</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
