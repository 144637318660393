import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-dropdown',
  templateUrl: './list-dropdown.component.html',
  styleUrls: ['./list-dropdown.component.css']
})
export class ListDropdownComponent implements OnInit {

    @Input() public typeId: number;
    @Input() public valueField = 'id';
    @Input() public name: string;
    @Input() public control: FormControl;
    @Input() public group: FormGroup;
    @Input() public defaultSelect: string = "Select option";
    @Input() public listDropdown: ListDropDownOption[] = [];

    constructor() {

    }

    ngOnInit() {
    }

    public getValue(option: ListDropDownOption) {
        return (this.valueField === 'name') ? option.name : option.id;
    }
}


export interface ListDropDownOption {
    id: number;
    name: string;
}
