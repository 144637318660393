<div class="container h-100">
    <div class="row align-items-center vh-100">
        <div class="col-12">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="intro-image"></div>
                </div>
                <div class="col-xs-12 col-md-6">
                    <div class="blankpage-form-field w-100">
                        <div
                            class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                            <img src="./assets/theme/img/logo.svg" alt="Digibits" aria-roledescription="logo">
                            <span class="page-logo-text  mr-1">Digibits Vendor</span>
                        </div>
                        <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                            <app-error-list [errorsList]="errorsList"></app-error-list>
                            <form id="js-login" [formGroup]="signupForm" (ngSubmit)="signup()" novalidate
                                autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-6">
                                        <label class="form-label" for="first_name">First Name</label>
                                        <app-form-error-wrapper [control]="signupForm.controls['first_name']"
                                            [controlName]="'First Name'">
                                            <input class="form-control form-control-lg rounded-0" type="text"
                                                formControlName="first_name" placeholder="First Name" #autofocus/>
                                        </app-form-error-wrapper>
                                    </div>
                                    <div class="form-group col-6">
                                        <label class="form-label" for="last_name">Last Name</label>
                                        <app-form-error-wrapper [control]="signupForm.controls['last_name']"
                                            [controlName]="'Last Name'">
                                            <input class="form-control form-control-lg rounded-0" type="text"
                                                formControlName="last_name" placeholder="Last Name" />
                                        </app-form-error-wrapper>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="form-label" for="username">Email</label>
                                    <app-form-error-wrapper [control]="signupForm.controls['email']"
                                        [controlName]="'Email'">
                                        <input class="form-control form-control-lg rounded-0" type="text"
                                            formControlName="email" placeholder="Email" />
                                    </app-form-error-wrapper>
                                </div>


                                <div class="row">
                                    <div class="form-group col-xs-12 col-sm-6">
                                        <label class="form-label" for="password">Password</label>
                                        <app-form-error-wrapper [control]="signupForm.controls['password']"
                                            [controlName]="'Password'">
                                            <input class="form-control form-control-lg rounded-0" type="password"
                                                formControlName="password" placeholder="Password" />
                                        </app-form-error-wrapper>
                                    </div>
                                    <div class="form-group col-xs-12 col-sm-6">
                                        <label class="form-label" for="password_confirmation">Confirm Password</label>
                                        <app-form-error-wrapper [control]="signupForm.controls['password_confirmation']"
                                            [controlName]="'Confirm Password'">
                                            <input class="form-control form-control-lg rounded-0" type="password"
                                                formControlName="password_confirmation"
                                                placeholder="Confirm Password" />
                                        </app-form-error-wrapper>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 my-4">
                                        <button #loginBtn type="submit" [disabled]="!signupForm.valid"
                                            class="shadow-0 btn btn-success btn-block btn-lg">SIGN UP</button>
                                    </div>
                                </div>
                            </form>
                            <div class="blankpage-footer text-center">
                                <span class="text-dark"> Already a member? </span> <a [routerLink]="'/login'"><strong>
                                        Secure Login
                                    </strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
