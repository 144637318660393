import { DashboardItems } from './crm-sidebar.model';
import { UserAccessGroupEnum } from './groups.model';
import { SettingsSubMenu } from './settings-sidebar.model';

export enum NavItemType {
  Menu,
  NavTitle
}

export const NavigationMenu = [
  {
    id: 0,
    title: 'Dashboard',
    link: '/portal/dashboard',
    icon: 'fal fa-shield-alt',
    type: NavItemType.Menu
  },
  {
    id: 1,
    title: 'Calendar',
    link: '/portal/calendar',
    icon: 'fal fa-calendar',
    type: NavItemType.Menu
  },
  {
    id: 13,
    title: 'People',
    link: 'javascript:;',
    icon: 'ni ni-users',
    type: NavItemType.Menu,
    subMenu: [
      {
        id: 20,
        title: 'Employees',
        link: '/portal/ems/employees',
        icon: 'fal fa-users',
      },{
        id: 21,
        title: 'Clients',
        link: '/portal/ems/clients/list',
        icon: 'fal fa-users',
      },
    ]
  },
  {
    id: 3,
    title: 'Payments',
    link: '/portal/vendor/payments',
    icon: 'fal fa-dollar-sign',
    type: NavItemType.Menu
  },
  {
    id: 3,
    title: 'Tasks',
    link: '/portal/tasks',
    icon: 'fal fa-tasks',
    type: NavItemType.Menu
  },

  {
    id: 5,
    title: 'Tickets',
    link: '/portal/tickets',
    icon: 'fal fa-ticket-alt',
    type: NavItemType.Menu
  },
  {
    id: 45,
    title: 'Payments',
    link: '/portal/ems/payments',
     icon: 'fal fa-dollar-sign'
  },
  {
    id: 6,
    title: 'Documents',
    link: '/portal/documents/list',
    icon: 'ni ni-docs',
    type: NavItemType.Menu
  },
  {
    id: 23,
    title: 'Invoices',
    link: '/portal/vendor/invoices/list-invoices',
    icon: 'fal fa-file-alt',
    type: NavItemType.Menu,
  },
  {
        id: 29,
        title: 'Credit Notes',
        link: '/portal/vendor/invoices/credit-note',
        icon: 'fal fa-sticky-note',
        type: NavItemType.Menu,
  },
  {
    id: 25,
    title: 'Expenses',
    link: '/portal/ems/expenses',
    icon: 'fal fa-calculator',
    type: NavItemType.Menu
  },
  {
    id: 24,
    title: 'Inventory',
    link: '/portal/vendor/dashboard',
    icon: 'fal fa-box',
    type: NavItemType.Menu
  },
  {
    id: 24,
    title: 'Reports',
    link: '/portal/vendor/reports',
    icon: 'fa-chart-pie fal',
    type: NavItemType.Menu
  },
  {
    id: 24,
    title: 'Fleet Management',
    link: '/portal/vendor/fleet',
    icon: 'fal fa-car',
    type: NavItemType.Menu,
    subMenu: [
        {
            id: 29,
            title: 'Refunds',
            link: '/portal/vendor/fleet-refund',
            icon: 'fal fa-cogs',
        }]
    },{
        id: 24,
        title: 'Fleet Stock',
        link: '/portal/vendor/fleet-stock',
        icon: 'fal fa-truck',
        type: NavItemType.Menu
      },{
        id: 24,
        title: 'Stock Intake',
        link: '/portal/vendor/stock-intake',
        icon: 'fal fa-barcode',
        type: NavItemType.Menu,
    }, {
        id: 10,
        title: 'Settings',
        type: NavItemType.NavTitle,
        accessLevel: [UserAccessGroupEnum.Administrator, UserAccessGroupEnum.Modrator]
    },
    {
        id: 10,
        title: 'Settings',
        link: 'javascript:;',
        icon: 'fal fa-cog',
        type: NavItemType.Menu,
        subMenu: SettingsSubMenu,
        // accessLevel: [UserAccessGroupEnum.Administrator, UserAccessGroupEnum.Modrator]
    },
];
