import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ListCommentsComponent } from './comments.component';
import { AddCommentComponent } from './add-comment/add-comment.component';

@NgModule({
  declarations: [
    ListCommentsComponent,
    AddCommentComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    ListCommentsComponent,
    AddCommentComponent
  ],
  entryComponents: [ListCommentsComponent],
  providers: []
})
export class CommentsModule {}
