import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ShortProfile, Registration } from './profile.model';
import { LocalStorageService } from '@app/core';
import { AuthenticationEvent } from '@app/core/authentication/authentication.model';
import { User } from '@app/shared/models/user.model';
import { UsersService } from '../users/users.service';
import { UserResponse } from '../users/models/user.model';
import { ClientResponse } from '@app/portal/EMS/clients/models/clients.model';
import { Client } from '@app/shared/models/client.model';
import { ClientService } from '@app/portal/EMS/clients/client.service';

@Injectable({ providedIn: 'root' })
export class ProfileService extends BaseAPIClass {
    public readonly STORAGE_KEY_PROFILE: string = 'ClientProfile';
    private readonly URL_REGISTER: string = 'auth/register';
    private readonly URL_UPDATE_PROFILE: string = 'profile/update';
    private readonly URL_FORGOT_PASSWORD: string = 'auth/forgot_password';
    private readonly URL_RESET_PASSWORD: string = 'auth/reset_password';
    private readonly URL_CHANGE_PASSWORD: string = 'auth/password';
    private readonly URL_EMPLOYEE_TIMEOFF_BALANCES: string = 'employees/timeoff/balances';

    private user: User = null;
    private client: Client = null;
    private loadingProfile = false;


    constructor(
    protected httpClient: HttpClient,
    protected authenticationService: AuthenticationService,
    private usersService: UsersService,
    private clientService: ClientService,
    private localStorageService: LocalStorageService ) {
        super(httpClient, authenticationService);
        this.authenticationSubscription = this.authenticationService.onAuthenticationChange.subscribe(
            this.handleAuthenticationChanged.bind(this)
        );
    }

    public authenticated() {
        return this.authenticationService.isAuthenticated();
    }

    public async getProfileAsync(refresh: boolean = false) {
        if(!this.loadingProfile) {
            this.loadingProfile = true;
            let user: User = null;
            if (!refresh) {
                user = this.user;
                if (user === null) {
                    const userResponse: UserResponse = await this.usersService.fetchMyProfileAsync();
                    user = userResponse.profile;
                }
            } else {
                const userResponse: UserResponse = await this.usersService.fetchMyProfileAsync();
                user = userResponse.profile;
            }
            this.user = user;
            this.loadingProfile = false;
            return user;
        } else {
            console.log('loading');
        }
    }


    public async getClientAsync(refresh: boolean = false) {
        if(!this.loadingProfile) {
            this.loadingProfile = true;
            let client: Client = null;
            if (!refresh) {
                client = this.client;
                if (client === null) {
                     const clientResponse: ClientResponse = await this.clientService.fetchClientAsync();
                    client = clientResponse.ClientProfile;
                }
            } else {
                const clientResponse: ClientResponse = await this.clientService.fetchClientAsync();
                client = clientResponse.ClientProfile;
            }
            this.client = client;
            this.loadingProfile = false;
            return client;
        } else {
            console.log('loading');
        }
    }

    public async registerAsync(registration: Registration) {
        const registeration = await this.postAsync<Client>(
        this.URL_REGISTER,
        registration
        ).toPromise();
        return registeration;
    }

    public async updateProfileAsync(profile: ShortProfile) {
        const updatedProfile = await this.postAsync<User>(
        this.URL_UPDATE_PROFILE,
        profile
        ).toPromise();
        this.saveProfileLocally(updatedProfile);
        return profile;
    }

    private saveProfileLocally(user?: User) {
        if (user) {
            this.localStorageService.setItem( this.STORAGE_KEY_PROFILE, JSON.stringify(user) );
        } else {
            this.localStorageService.clearItem(this.STORAGE_KEY_PROFILE);
        }
    }

    private async handleAuthenticationChanged(event: AuthenticationEvent) {
        if (event.status === 'logged-in') {
            this.saveProfileLocally();
            await this.getProfileAsync(true);
        } else if (event.status === 'logged-out') {
            this.saveProfileLocally();
        }
    }

    public async generatePasswordResetEmail(email: string) {
        return await this.postAsync(
            this.URL_FORGOT_PASSWORD,
            { email },
            [],
            true
        ).toPromise();
        }

    public async resetPassword(data: any) {
        return await this.postAsync(
            this.URL_RESET_PASSWORD,
            data,
            [],
            true
        ).toPromise();
    }

    public async changePassword(data: any) {
        return await this.postAsync(this.URL_CHANGE_PASSWORD, data, []).toPromise();
    }

    public async getUser() {
        if (this.user === null) {
            await this.getProfileAsync();
        }
        return this.user;
    }

    public async fetchTimeOffBalances() {
        return await this.postAsync<any>(this.URL_EMPLOYEE_TIMEOFF_BALANCES, {}, {}, false).toPromise();
    }
}
